<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <template v-if="account">
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="red white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete account
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <p class="text-overline mb-0">Account name</p>
                            <p class="mb-0 pb-0">
                                {{ account.name }}
                            </p>

                            <p class="text-overline mb-0">Confirmation</p>

                            <p>Tap on the button below to delete this account, including all domains, sites, apps, and other related data.</p>
                            <p class="font-weight-bold">This action is not reversible.</p>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete my account and all associated data"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand this will also unpublish all my brand assets from the BrandProfile CDN"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I understand this action is not reversible, and that customer support will not be able to help me recover the data."></v-checkbox>
                            <v-btn color="red" class="white--text" @click="deleteAccount" :disabled="!isDeleteAccountConfirmed">Delete Account</v-btn>
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to delete this account, including all domains, sites, apps, and other related data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteAccountImmediately">Delete Account</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>

            </template>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        account: null,
        list: [],
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isDeleteAccountConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`SearchForm.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomainList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.search();
                if (Array.isArray(response.list)) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('failed to load domain list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        async deleteAccount() {
            try {
                this.$store.commit('loading', { deleteAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                    this.$router.push({ name: 'user-dashboard' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteAccount: false });
            }
        },
        /**
         * Service admin can delete the account and data immediately
         */
        async deleteAccountImmediately() {
            try {
                this.$store.commit('loading', { deleteAccountImmediately: true });
                const response = await this.$client.main().service.deleteAccount({ account_id: this.$route.params.accountId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                    this.$router.push({ name: 'user-dashboard' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteAccountImmediately: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomainList();
    },
};
</script>
